/*global*/
import React, { Component } from 'react';

class TabularData extends Component {
    render () {
        /*
            Data expected in this format:
            data = {
                headers: [
                    {value: <value>, minWidth: <minWidth>, maxWidth: <maxWidth}
                ],
                rows: [
                    {
                        cols: [
                            {value: <value>}
                        ]
                    }
                ]
            }
        */
        const headers = this.props.data.headers;
        const rows = this.props.data.rows;
        if (headers === undefined || rows === undefined) {
            return <div></div>
        }
        if (Array.isArray(headers) !== true || Array.isArray(rows) !== true) {
            return <div></div>
        }
        let headerData = [];
        for (let i = 0; i < headers.length; i++) {
            let header = headers[i];
            let style = {"textAlign":"left"}
            if (header.minWidth !== undefined) {
                style["minWidth"] = header.minWidth;
            }
            if (header.maxWidth !== undefined) {
                style["width"] = header.maxWidth;
            }
            headerData.push(
                <th key={i} style={style}>
                    {header.value}
                </th>
            )
        }
        headerData = 
        <tr className="font14 neutral3">
            {headerData}
        </tr>
        let rowData = [];
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            let cols = row.cols;
            let colsData = [];
            for (let j = 0; j < cols.length; j++) {
                colsData.push(
                    <td key={j} style={{"verticalAlign": "baseline"}}>
                        {cols[j].value}
                    </td>
                )
            }

            let bg = ""
            if (this.props.highlight) {
                let field = this.props.highlight.field
                let color = this.props.highlight.color
                if (field && color) {
                    let cols = row.cols
                    for (let i = 0; i < cols.length; i++) {
                        let col = cols[i]
                        if (color[col.value]) {
                            bg = color[col.value]
                        }
                    }
                }
            }
            if (bg === undefined) {
                bg = ""
            } else {
                bg = " " + bg
            }
            rowData.push(
                <tr key={i} className={"font15 neutral2" + bg}>
                    {colsData}
                </tr>
            )
        }
        return (
            <div>
                <div className="neutral4 font14">Total: {rowData.length}</div>
                <div className="standardborder solidborder neutral7border p-3">
                    <div className="tabular-data">
                         <table>
                             {headerData}
                             {rowData}
                         </table>
                    </div>
                </div>
            </div>
        );
    }
};

export default TabularData;