/*global */
import React, { Component } from 'react';

class FairBillingPolicy extends Component {
    render() {
        return (
            <div>
                <h3 className="neutral3">
                    <div className='flex-row' style={{"alignItems":"baseline"}}>
                        <div className='mr-3'><img alt="heart" src="/img/heart.svg" width="16" height="16"/></div>
                        <div>Fair billing policy</div>
                    </div>
                </h3>
                <p className="neutral3">When you are ready to move to a paid subscription on the Standard or Plus plans, Tesults has a fair billing policy. You are only billed for users that actually use Tesults. There is no need for your IT administrator to remove seats to save on costs, Tesults automatically recognizes inactive users and stops billing for them to ensure cost efficiency for your organization.</p>
                <div style={{"height":"100vh"}}>
                    <details>
                        <summary><span className='neutral2 underline_dotted'>How it works</span></summary>
                        <div>
                            <p className="neutral3">Add users (team members) to your Tesults project and only get billed for active users. An active user is a user that has accessed Tesults within the last 30 days. Tesults automatically recognizes inactive users and stops charging for them. When a user becomes inactive part way through the billing cycle you receive a non-refundable credit on your account. You are billed for a minimum of 1 active user on the Standard and Plus plans. Cancel at any time.</p>
                            <p className="neutral3">If you choose to pay yearly instead of monthly, you are billed yearly but if your active users goes up you will also be billed for new active users on the first day of the following month. If you prefer to have a stable bill, set the minimum active users to bill for to a sufficient level to avoid this and you will only be billed yearly. You can also set the minimum active users to bill for on the monthly plan if you want to.</p>
                        </div>
                    </details>
                    <details>
                        <summary><span className='neutral2 underline_dotted'>Why this is great</span></summary>
                        <div>
                            <p className="neutral3">Tesults used to bill based on the number of targets and data uploaded. This was confusing and required a lot of thought for teams. With the new <b>active user pricing</b> the number of targets (test jobs) you can create is unlimited on the Standard and Plus plans and this gives teams much greater flexibility. Pricing by active user allows teams to budget easily. Have a team of 5 and you're on the standard plan? You can expect to pay less than $100/month, no matter how many targets the team creates. This means no more pre-planning or having to think about usage. Also, at Tesults we just don't think it's right to pay for something you don't use so we automatically stop charging for inactive users resulting in automatic peace of mind for budget conscious teams and efficiency for larger teams.</p>
                        </div>
                    </details>
                </div>
            </div>
        )
    }
}

export default FairBillingPolicy;