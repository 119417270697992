/*global*/
import React, { Component } from 'react';
import TimeFormatted from './TimeFormatted';
import Request from './Request';
import Cache from './Cache';
import Analytics from './Analytics';
import Context from './Context';

class ProjectUsage extends Component {
    // Targets use/limit
    // Team Members use/limit
    // Project Icon true/false
    // Target Icon true/false
    // Avatar Icon true/false
    // TestRuns over last 24 hours
    // TestRuns over last 7 days
    // TestRuns over last 30 days
    // Test Cases in last test run
    // Project data files usage
    // Project data storage usage
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = ({display: true, role: 1, usage: undefined});
        this.toggleDisplay = this.toggleDisplay.bind(this);
        this.projectRole = this.projectRole.bind(this);
        this.getProjectUsage = this.getProjectUsage.bind(this);
        this.renderFileAndDataUsage = this.renderFileAndDataUsage.bind(this);
        this.renderAllUsage = this.renderAllUsage.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.projectId !== this.props.projectId) {
            this.getProjectUsage();
        }
    }

    componentDidMount () {
        let projectUsageDisplay = Cache.getPreference(Cache.preference.projectUsageDisplay);
        if (projectUsageDisplay === undefined) {
            Cache.setPreference(Cache.preference.projectUsageDisplay, true);
        } else {
            if (projectUsageDisplay === false) {
                this.setState({display: false});
            }
        }
        this.projectRole();
    }

    projectRole () {
        Request.get("/projectRole", {id: this.props.projectId}, (err, data) => {
            if (err) {
                this.setState({role: 1});
            } else {
                this.setState({role: data.role});
                if (data.role >= 3) {
                    this.getProjectUsage();
                }
            }
        });
    }

    toggleDisplay () {
        Analytics.event("ProjectUsageToggle");
        Cache.setPreference(Cache.preference.projectUsageDisplay, this.state.display === true ? false: true);
        this.setState({display: this.state.display === true ? false: true});
    }

    getProjectUsage() {
        if (this.props.projectId === undefined) {
            return;
        }
        if (this.state.role < 3) {
            return;
        }
        Request.get("/projectUsage", {id: this.props.projectId}, (err, data) => {
            if (err) {
                this.setState({usage: undefined});
            } else {
                let usage = {};
                usage.targets = {};
                usage.targets.used = data.targets.used;
                usage.targets.limit = data.targets.limit;
                usage.users = {};
                usage.users.used = data.users.used;
                usage.users.limit = data.users.limit;
                usage.projectIcon = data.projectIcon;
                usage.targetIcons = {};
                usage.targetIcons.used = data.targetIcons.used;
                usage.targetIcons.limit = data.targetIcons.limit;
                usage.avatarIcon = data.avatarIcon;
                usage.files = data.fileUsedPercent;
                usage.data = data.dataUsedPercent;
                usage.dataReset = data.dataReset;
                this.setState({usage: usage});
            }
        });
    }

    renderFileAndDataUsage () {
        const usage = this.state.usage;
        let content = 
        <div>
            <span className="font12">{usage.data} % data</span><br/>
            <span className="font12">{usage.files} % files</span><br/>
            <span className="font12">Resets: <TimeFormatted dt={usage.dataReset}/></span><br/>
        </div>
        let indicator = <div className="circle12 accentc4bg accentc4border" alt="" style={{"marginTop":"-8px"}}/>
        if (usage.data < 75 && usage.files < 75) {
            content = 
            <div>
                <span className="font12">Healthy 0-75%</span><br/>
                <hr className='primary8border'/>
                <span className="font12">Resets: <TimeFormatted dt={usage.dataReset}/></span><br/>
            </div>
        } else if ((usage.data >= 75 && usage.data < 95) || (usage.files >= 75 && usage.files < 95)) {
            content =
            <div>
                <span className="font12">75-95% usage.</span><br/>
                <span className="font12">Results data is always saved successfully regardless of data usage. File uploads stop at 99-100% of limit.</span><br/>
                <hr className='primary8border'/>
                <span className="font12">Resets: <TimeFormatted dt={usage.dataReset}/></span><br/>
                <hr className='primary8border'/>
                <span className="font12">Increase limit by adding more team members if on Standard or Plus plan. For custom plans contact help@tesults.com.</span><br/>
            </div>
            indicator = <div className="circle12 accentb4bg accentb4border" alt="" style={{"marginTop":"-8px"}}/>
        } else if (usage.data >= 95 || usage.files >= 95) {
            content =
            <div>
                <span className="font12">Results data is always saved successfully regardless of data usage. File uploads stop at 99-100% of limit.</span><br/>
                <hr className='primary8border'/>
                <span className="font12">{usage.data} % data</span><br/>
                <span className="font12">{usage.files} % files</span><br/>
                <hr className='primary8border'/>
                <span className="font12">Resets: <TimeFormatted dt={usage.dataReset}/></span><br/>
                <hr className='primary8border'/>
                 <span className="font12">Increase limit by adding more team members if on Standard or Plus plan. For custom plans contact help@tesults.com.</span><br/>
            </div>
            indicator = <div className="circle12 accenta4bg accenta4border" alt="" style={{"marginTop":"-8px"}}/>
        }
        return (
            <div className="pl-3 pr-3">
            <div style={{"display:":"flex", "flexDirection":"column", "maxWidth":"220px"}} className="p-4 primary8border standardborder solidborder mt-5 mb-5">
                <div style={{"display":"flex"}} className="mb-2">
                    <div className="primary7 font14 bold">Project data usage</div>
                    <div style={{"marginLeft":"auto", "marginTop":"-4px", "marginRight":"-4px"}}>
                        <button onClick={this.toggleDisplay} className="btn-transparent btn-close-primary7-sm pt-0 mt-0">
                            &times;
                        </button>
                    </div>
                </div>
                <div style={{"display":"flex"}}>
                    <div className="mr-4">
                        {content}
                    </div>
                    <div className="primary7" style={{"marginLeft":"auto", "marginTop":"-4px", "marginRight":"-12px"}}>
                        <div className="menu-item-circle">
                            {indicator}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }

    renderAllUsage () {
        let usage = this.state.usage;
        let projectUsage = undefined;
        let avatarUsage = undefined;
        let suggestion = <div></div>
        if (usage.projectIcon === true) {
            projectUsage = "Project icon is set.";
        } else {
            projectUsage = "Project icon is not set.";
        }
        if (usage.avatarIcon === true) {
            avatarUsage = "Your profile image is set.";
        } else {
            avatarUsage = "Your profile image is not set.";
        }
        if (usage.users.used === 1) {
            suggestion = <p className="fail">Only you can currently view your results. Add team members.&nbsp;</p>
        }
        return (
            <div className="row">
                <div className="col-12 col-sm-12, col-md-12, col-lg-12">
                    <a data-toggle="collapse" href="#projectUsage" aria-expanded="false" aria-controls="projectUsage" className="card-link"><span className="tr-link-dark font14"><img src="/img/folder-outline.svg" style={{"marginTop": "-4px"}} width="16" height="16" alt=""/> Project usage</span></a>
                    <div className="collapse" id="projectUsage">
                        <div className="card mb-3">
                            <div className="card-header">
                                Only visible to project administrators
                            </div>
                            <div className="card-body">
                                <span className="">{usage.users.used} team members added out of {usage.users.limit}</span><br/>
                                <span className="">{usage.targets.used} targets created out of {usage.targets.limit}</span><br/>
                                <span className="">{usage.targetIcons.used} target icons set out of {usage.targetIcons.limit} <img src="/img/target-default-128.png" alt="" width="16" height="16"/></span><br/>
                                <span className="">{projectUsage} <img src="/img/project-default-128.png" alt="" width="16" height="16"/></span><br/>
                                <span className="">{avatarUsage} <img src="/img/tasks-128.png" alt="" width="16" height="16"/></span><br/>
                                <span className="">Data usage: {usage.data} % storage {usage.files} % files</span><br/>
                                <span className="">Usage reset: <TimeFormatted dt={usage.dataReset}/></span><br/>
                                {suggestion}
                                <span className="">Increase target and team member limits from the configuration menu.</span><br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.usage === undefined) {
            return (
                <div></div>
            );
        }
        
        if (this.state.role === undefined ||this.state.role < 3) {
            return (
                <div></div>
            );
        }

        if (this.props.full !== undefined) {
            if (this.props.full !== true) {
                return <span></span>
            }
        }

        if (this.state.display !== true) {
            return (
                <div style={{"display:":"flex", "flexDirection":"column", "maxWidth":"220px"}} className="p-4">
                    <div style={{"display":"flex"}} className="mb-2">
                        <div>
                            <span className="font12 primary7">Project data usage</span>
                        </div>
                        <div style={{"marginLeft":"auto", "marginTop":"-4px"}}>
                            <button onClick={this.toggleDisplay} className="btn-transparent btn-close-primary7-sm pt-0 mt-0 neutral7border">
                                <span className="font12 primary7">+</span>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

        return this.renderFileAndDataUsage();
    }
}

export default ProjectUsage;