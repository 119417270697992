/*global */
import React, { Component } from 'react';
import InfoToolTip from './InfoToolTip';
import FairBillingPolicy from './FairBillingPolicy';
import { Link, NavLink } from 'react-router-dom';
//import Constants from './Constants';
//import Analytics from './Analytics';

class PricingItem extends Component {
    constructor () {
        super();
        this.state = {
            width: window.innerWidth, 
            height: window.innerHeight
        }
        this.resizeEvent = this.resizeEvent.bind(this);
    }

    componentDidMount () {
        window.addEventListener('resize', this.resizeEvent);
    }
   
    componentWillUnmount() {
       window.removeEventListener('resize', this.resizeEvent);
    }

    resizeEvent () {
        this.setState({ width: window.innerWidth, height: window.innerHeight});
    }

    renderLabels () {
        return (
            <div className='pricing-item-labels mb-5'>
                <div className='pricing-item-header'>
                    <div className='font20 bold'>&nbsp;</div>
                    <div className='font13 neutral4' style={{"minHeight":"42px"}}>&nbsp;</div>
                    <div className='font32 bold' style={{"minHeight":"54px"}}>&nbsp;</div>
                    <div className='font13 neutral4 mb-3' style={{"minHeight":"22px"}}>&nbsp;</div>
                    <div>{this.props.itemSelectButton}</div>
                </div>
                <div className={'pricing-item-fair-billing-policy' + (this.props.itemShowFairBillingOnDesktop === true ? " pricing-item-fair-billing-policy-show-on-desktop": "")}>{(this.props.itemFairBilling !== true) ? <div></div>  : <div><img alt="heart" src="/img/heart.svg" width="14" height="14"/> <b>Fair Billing Policy</b> - Automatically stops charging for inactive users. <NavLink target="_blank" to="/docs/fair-billing-policy" className="neutral4 pricing-item-link no-break">More info</NavLink></div>}</div>
                <div className='pricing-item-group'>Limits</div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Test cases per run 
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        <NavLink target="_blank" to="/docs/target" className="neutral4 pricing-item-link">Targets</NavLink>
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Lists and runs
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Test cases per list and run
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Release checklists
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Files data storage
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Files uploads
                    </div>
                </div>
                <div className='pricing-item-group'>Enhanced Analysis and AI</div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Case Enhanced Analysis
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-label'>
                        Tesults AI
                    </div>
                </div>
                <details open={this.state.width <= 750 ? false: true}>
                    <summary className='pricing-item-more-details primary4 pointer'>More details</summary>
                    <div>
                        <div className='pricing-item-group'>Data retention and audit</div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Automated test results history
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Automated test run archiving
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Manual test runs in progress
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Manual test runs archiving
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Audit Logs
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Unlimited test list retention
                            </div>
                        </div>
                        <div className='pricing-item-group'>Team</div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Users
                            </div>
                        </div>
                        <div className='pricing-item-group'>Security</div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Two-factor authentication
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Google OAuth
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                SSO (SAML2.0)
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Data encrypted at rest
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Data encrypted in transit
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Own private keys encryption
                            </div>
                        </div>
                        <div className='pricing-item-group'>Integrations</div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Third-party integrations
                            </div>
                        </div>
                        <div className='pricing-item-group'>Rate Limits</div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Rate limits
                            </div>
                        </div>
                        <div className='pricing-item-group'>Terms of service & support</div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Terms of service
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-label'>
                                Support
                            </div>
                        </div>
                    </div>
                </details>
            </div>
        )
    }

    render () {
        if (this.props.type === "labels") {
            return this.renderLabels()
        }

        const noImg = <img src="/img/pricing-item-cross.svg" width="21" height="21"/>
        const yesImg = <img src="/img/pricing-item-check.svg" width="21" height="21"/>

        return (
            <div className={'pricing-item mb-5 ' + (this.props.itemAccent ? this.props.itemAccent : "pricing-item-free")}>
                <div className='pricing-item-header'>
                    <div className='font20 bold'>{this.props.itemName}</div>
                    <div className='font13 neutral4' style={{"minHeight":"42px"}}>{this.props.itemDesc}</div>
                    <div className='font32 bold' style={{"minHeight":"54px"}}>{this.props.itemPrice}</div>
                    <div className='font13 neutral4 mb-3' style={{"minHeight":"22px"}}>{this.props.itemUnit}</div>
                    <div>{this.props.itemSelectButton}</div>
                </div>
                <div className={'pricing-item-fair-billing-policy' + (this.props.itemShowFairBillingOnDesktop === true ? " pricing-item-fair-billing-policy-show-on-desktop": "")}>{(this.props.itemFairBilling !== true) ? <div></div>  : <div><img alt="heart" src="/img/heart.svg" width="14" height="14"/> <b>Fair Billing Policy</b> - Automatically stops charging for inactive users. <NavLink target="_blank" to="/docs/fair-billing-policy" className="neutral4 pricing-item-link no-break">More info</NavLink></div>}</div>
                <div className='pricing-item-group'><span className="price-item-group-title">Limits</span></div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>
                        Test cases per run 
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemCases}
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>
                        <NavLink target="_blank" to="/docs/target" className="neutral4 pricing-item-link">Targets</NavLink>
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemTargets}
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>
                        Lists and runs
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemListsAndRuns}
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>
                        Test cases per list and run
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemCasesManual}
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>
                        Release checklists
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.releaseChecklists}
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>
                        Files data storage
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemData}
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>
                        Files uploads
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemFiles}
                    </div>
                </div>
                <div className='pricing-item-group'><span className="price-item-group-title">Enhanced Analysis</span></div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>    
                        Case Enhanced Analysis
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemCaseEnhancedAnalysis ? yesImg : noImg}
                    </div>
                </div>
                <div className='pricing-item-field-value'>
                    <div className='pricing-item-field'>    
                        Tesults AI
                    </div>
                    <div className='pricing-item-value'>
                        {this.props.itemAI ? yesImg : noImg}
                    </div>
                </div>
                <details open={this.state.width <= 750 ? false: true}>
                    <summary className='pricing-item-more-details primary4 pointer'>More details</summary>
                    <div>
                        <div className='pricing-item-group'><span className="price-item-group-title">Data retention and audit</span></div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Automated test results history
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemHistory}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Automated test run archiving
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemRunArchiveLimit === false ? noImg : this.props.itemRunArchiveLimit}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Manual test runs in progress
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemManualRunsInProgress}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Manual test runs archiving
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemManualRunArchiving ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Audit Logs
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemAuditLogs ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Unlimited test list retention
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemUnlimitedListRetention ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-group'><span className="price-item-group-title">Team</span></div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Users
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemUsers}
                            </div>
                        </div>
                        <div className='pricing-item-group'><span className="price-item-group-title">Security</span></div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Two-factor authentication
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.item2FA ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Google OAuth
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemGoogleOAuth ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                SSO (SAML2.0)
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemSSO ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Data encryption at rest
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemEncryption ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Data encryption in transit
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemEncryption ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Own private keys encryption
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemPrivateKey ? yesImg : noImg}
                            </div>
                        </div>
                        <div className='pricing-item-group'><span className="price-item-group-title">Integrations</span></div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Third-party integrations
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemIntegrations}
                            </div>
                        </div>
                        <div className='pricing-item-group'><span className="price-item-group-title">Rate Limits</span></div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Rate limits
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemRateLimits}
                            </div>
                        </div>
                        <div className='pricing-item-group'><span className="price-item-group-title">Terms of service & support</span></div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Terms of service
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemTermsOfService}
                            </div>
                        </div>
                        <div className='pricing-item-field-value'>
                            <div className='pricing-item-field'>
                                Support
                            </div>
                            <div className='pricing-item-value'>
                                {this.props.itemSupport}
                            </div>
                        </div>
                    </div>
                </details>
            </div>
        )
    }
}

export default PricingItem;