/*global */
import Request from './Request';

class Cache {
    // Local storage used for user preferences
    // Context, in memory cache, used for global data

    static data = {
        projects: {key: "projects", api: "/projects", type: "get"},
        targets: {key: "targets", api: "/targets", type: "get"}
    }

    static preference = {
        projectIndex: {key: "projectIndex", type: "number"},
        targetIndex: {key: "targetIndex", type: "number"},
        resultsView: {key: "resultsView", type: "string"},
        listGroupTreeEdit: {key: "listGroupTreeEdit", type: "boolean"},
        listIndex: {key: "listIndex", type: "number"},
        importListIndex: {key: "importListIndex", type: "number"},
        manualRunInProgressIndex: {key: "manualRunInProgressIndex", type: "number"},
        saveToListId: {key: "saveToListId", type: "string"},
        targetGroup: {key: "targetGroup", type: "string"},
        darkMode: {key: "darkMode", type: "boolean"},
        projectInviteDisplay: {key: "projectInviteDisplay", type: "boolean"},
        projectUsageDisplay: {key: "projectUsageDisplay", type: "boolean"},
        teamIndex: {key: "teamIndex", type: "number"},
        /* to be deleted: */
        tasksProjectId: {key: "tasksProjectId", type: "string"},
        tasksTargetCreated: {key: "tasksTargetCreated", type: "string"},
        tasksMemberId: {key: "tasksMemberId", type: "string"},
        menu: {key: "menu", type: "boolean"},
        jiraProjectId: {key: "jiraProjectId", type: "string"},
        jiraIssueTypeId: {key: "jiraIssueTypeId", type: "string"},
        jiraPriorityId: {key: "jiraPriorityId", type: "string"},
        jiraTransitionId: {key: "jiraTransitionId", type: "string"},
        sideClose: {key: "sideClose", type: "boolean"}
    }

    static request (context, data, params, callback) {
        if (context[data.key] !== undefined && params.override !== true) {
            callback(undefined, context[data.key]);
        } else {
            if (data.type === "get") {
                Request.get(data.api, params, (err, responseData) => {
                    if (err) {
                        callback(err);
                    } else {
                        context.update(data.key, responseData[data.key]);
                        callback(undefined, context[data.key]);
                    }
                });
            } else if (data.type === "post") {
                Request.post(data.api, params, (err, responseData) => {
                    if (err) {
                        callback(err);
                    } else {
                        context.update(data.key, responseData[data.key]);
                        callback(undefined, context[data.key]);
                    }
                });
            } else {
                callback("Invalid type");
            }
        }
    }

    static getPreference (preference) {
        const numberValue = (value) => {
            value = parseInt(value, 10);
            return isNaN(value) ? 0 : value;
        }
    
        const booleanValue = (value) => {
            return value === "true" ? true: false;
        }

        let value = window.localStorage.getItem(preference.key);
        if (value === null) {
            return undefined;
        }
        if (preference.type === "number") {
            return numberValue(value);
        } else if (preference.type === "boolean") {
            return booleanValue(value);
        } else {
            return value;
        }
    }

    static setPreference (preference, value) {
        window.localStorage.setItem(preference.key, value.toString());
    }
}

export default Cache;