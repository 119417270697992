
/*global */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

class DocsBuildConsolidation extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Consolidate parallel test runs with build consolidation on Tesults</title>
                    <meta name="description" content="Learn how to consolidate parallel test runs with build consolidation on Tesults."/>
                </Helmet>
                <div className="mb-3">
                    <iframe className='youtube' src="https://www.youtube.com/embed/lMtZXug_44M?si=UVHvazhIztjgVlx1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <p>Go to the <b>Configuration</b> menu.</p>
                    <Link target="_blank" to="/config">
                        <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                    </Link>
                    <p>Select Build Consolidation.</p>
                    <p>When executing multiple test runs in parallel or serially for the same build or release, results are submitted to Tesults separately and multiple test runs are generated on Tesults. This is because the default behavior on Tesults is to treat each results submission as a separate test run.</p>
                    <p>This behavior can be changed from the configuration menu.</p>
                    <h2>Build Consolidation</h2>
                    <p>Click 'Build Consolidation' from the Configuration menu to enable and disable consolidation for a project or by target.</p>
                    <p>When build consolidation is enabled multiple test runs submitted at different times, with the same build name, will be consolidated into a single test run by Tesults automatically.</p>
                    <p>This is useful for test frameworks that run batches of test cases in parallel. If you do not have a build name to use for consolidation, consider using a timestamp set at the time the test run starts.</p>
                    <h2>Build Replacement</h2>
                    <p>When build consolidation is enabled, an additional option, build replacement can optionally be enabled too. Just as with build consolidation, when multiple test runs are submitted with the same build name the results are consolidated, but with replacement enabled, if there are test cases with the same suite and name received multiple times, the last received test case replaces an existing test case with the same suite and name. This may be useful to enable in situations where test cases are re-run frequently and you do not want new test cases to be appended and instead want them to replace older test cases. This option is generally best left disabled, unless test cases are often re-run for the same build and you are only interested in the latest result for the run.</p>
                </div>
            </div>
        );
    }
}

export default DocsBuildConsolidation;